import isMobile from 'ismobilejs';

const is_mobile = isMobile().phone || window.innerWidth <= 600;

window.addEventListener('DOMContentLoaded', () => {
    pagesAnimation();
})

const pagesAnimation = () => {
    const articles = document.querySelectorAll('.home-pages--wrapper article');

    const observer = new IntersectionObserver((e) => {
        e.map(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('show');
            }
        })
    }, {
        rootMargin: is_mobile ? '0px' : '-400px'
    })
    console.log('px')

    Object.values(articles).map(article => observer.observe(article));
}
